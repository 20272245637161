import { default as indexWC1vWaeqmVMeta } from "/codebuild/output/src3418197868/src/src/pages/forgot-password/index.vue?macro=true";
import { default as updateq0GFWF4EJnMeta } from "/codebuild/output/src3418197868/src/src/pages/important-news/[postId]/update.vue?macro=true";
import { default as createrJmAEugdaxMeta } from "/codebuild/output/src3418197868/src/src/pages/important-news/create.vue?macro=true";
import { default as indexroziaFoAqBMeta } from "/codebuild/output/src3418197868/src/src/pages/index.vue?macro=true";
import { default as indexS6bHngq6wNMeta } from "/codebuild/output/src3418197868/src/src/pages/login/index.vue?macro=true";
import { default as updatetzmo9fL4utMeta } from "/codebuild/output/src3418197868/src/src/pages/news/[postId]/update.vue?macro=true";
import { default as createFF4YlffsSAMeta } from "/codebuild/output/src3418197868/src/src/pages/news/create.vue?macro=true";
import { default as indexqA2685lK3eMeta } from "/codebuild/output/src3418197868/src/src/pages/update-password/index.vue?macro=true";
export default [
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexWC1vWaeqmVMeta || {},
    component: () => import("/codebuild/output/src3418197868/src/src/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "important-news-postId-update",
    path: "/important-news/:postId()/update",
    component: () => import("/codebuild/output/src3418197868/src/src/pages/important-news/[postId]/update.vue").then(m => m.default || m)
  },
  {
    name: "important-news-create",
    path: "/important-news/create",
    component: () => import("/codebuild/output/src3418197868/src/src/pages/important-news/create.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3418197868/src/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexS6bHngq6wNMeta || {},
    component: () => import("/codebuild/output/src3418197868/src/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "news-postId-update",
    path: "/news/:postId()/update",
    component: () => import("/codebuild/output/src3418197868/src/src/pages/news/[postId]/update.vue").then(m => m.default || m)
  },
  {
    name: "news-create",
    path: "/news/create",
    component: () => import("/codebuild/output/src3418197868/src/src/pages/news/create.vue").then(m => m.default || m)
  },
  {
    name: "update-password",
    path: "/update-password",
    meta: indexqA2685lK3eMeta || {},
    component: () => import("/codebuild/output/src3418197868/src/src/pages/update-password/index.vue").then(m => m.default || m)
  }
]