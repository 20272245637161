<template>
  <div class="container">
    <h1 class="title mb-0">エラーが発生しました</h1>
    <p class="mb-4 text-center">以下のボタンでホームにお戻りください。</p>
    <Button
      class="mx-auto block"
      @click="
        clearError({
          redirect: '/',
        })
      "
      >ホームに戻る</Button
    >
  </div>
</template>
