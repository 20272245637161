<template>
  <NuxtErrorBoundary @error="onError">
    <NuxtLayout>
      <NuxtPage />
      <Toaster />
    </NuxtLayout>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
const { $sentryCaptureException } = useNuxtApp();
const { stopAll } = useLoadingState();
useHead({
  titleTemplate: '%s | リロクリエイト 個社管理画面',
});

const onError = (error: any) => {
  console.error(error);
  $sentryCaptureException(error, (scope) => {
    scope.setTransactionName(error.message || undefined);
    return scope;
  });
  stopAll();
  showError(error);
};
</script>
