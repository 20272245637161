import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap align-bottom text-sm font-medium transition-all hover:opacity-70 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white',
        outline: 'border border-textLight',
      },
      size: {
        default: 'h-10 px-4 py-2 text-base',
        sm: 'h-8 px-3 py-1 text-xs',
      },
      rounded: {
        default: 'rounded',
        none: 'rounded-none',
        full: 'rounded-full',
      },
      color: {
        default: 'text-primary',
        secondary: 'text-text',
        danger: 'bg-error text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: 'default',
      color: 'default',
    },
    compoundVariants: [
      {
        variant: 'default',
        color: 'default',
        class: 'text-white',
      },
      {
        variant: 'outline',
        color: 'default',
        class: 'border-primary',
      },
    ],
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
