import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function nonNullables<T>(args: (T | undefined | null)[]): T[] {
  return args.filter((arg): arg is NonNullable<T> => arg != null) as T[];
}

/**
 * https://hoge/fuga/test.hoge のファイル名を取得する関数
 */
export function getFileNameFromUrl(url: string): string {
  return url.split('/').pop() ?? 'ファイル名が取得できませんでした';
}
