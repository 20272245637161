export const useLoadingState = () => {
  const loadingSet = useState<Set<string>>('loadingState', () => new Set());

  /**
   * ローディングを開始する
   * 返り値の関数を呼ぶことでそのkeyのローディングを終了する
   *
   * @returns stopLoading 開始したkeyのローディングを終了する関数
   */
  const startLoading = (key: string) => {
    loadingSet.value.add(key);

    return () => _stopLoading(key);
  };

  /**
   * ローディングを終了する
   * @param key
   */
  const _stopLoading = (key: string) => {
    loadingSet.value.delete(key);
  };

  /**
   * 全てのローディングを終了する
   */
  const stopAll = () => {
    loadingSet.value.clear();
  };

  /**
   * ローディング中かの判定
   */
  const isLoading = computed(() => !!loadingSet.value.size);

  return {
    isLoading,
    startLoading,
    stopAll,
  };
};
